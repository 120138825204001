<template>
  <div class="w-full bg-gray-50 p-2 pb-4 flex flex-row">
    <modal :modal="modal"></modal>
    <div class="rounded border-2 border-gray-200 flex flex-row p-2 bg-white h-full w-full mr-1">
      <div class="w-24 flex flex-col border-r-2 border-gray-200 pr-2">
        <div v-for="part in parts"  v-bind:key="part.id" v-on:click="selectMain(part)" :class="['p-2 cursor-pointer border-2 border-white hover:bg-blue-50 hover:border-blue-500 rounded min-h-16', partActive == part ? 'border-blue-500' : '']">
          <img :src="require(`@/assets/img/damages/img/${part}.png`)">
        </div>
      </div>
      <div :class="['w-full', part=='general' ? '' : 'hidden']">
        <div :class="['w-full text-blue-500 p-8 flex flex-col justify-start items-center', partActive=='' ? '' : 'hidden']">
          Select part on the left
        </div>
        <div id="svgPartContainer" :class="['w-full p-8 flex flex-col justify-start items-center', partActive=='' ? 'hidden' : '']">
          <div :class="['w-full text-blue-500 pt-2 pb-8 flex flex-col justify-start items-center h-12',]">
            {{ getLocationName() }}
          </div>
          <div v-for="part in parts" v-bind:key="part.id"  :class="['w-full cursor-pointer', part===partActive ? '' : 'hidden']">
            <img :id="'svg-part-' + part" class="w-full" :src="require(`@/assets/img/damages/${part}.svg`)" />
          </div>
        </div>
      </div>
      <div v-if="part=='type' || part=='severity'|| part=='pictures'" class="w-full">
        <div class="w-full px-2 pb-2 flex flex-col justify-start items-start">
          <div class="flex flex-col w-full">
            <div class="flex flex-row">
              <div class="w-64 p-3 flex h-24 justify-center items-end">
                <img :src="require(`@/assets/img/damages/img/${damage.item}.png`)" class="max-h-full max-w-full">
              </div>
              <div class="p-3 flex flex-col w-full">
                <div class="font-medium">{{ damage.locationText }}</div>
                <div class="font-medium text-gray-500">{{ damage.typeText }}</div>
                <div class="font-medium text-gray-500">{{ damage.severityText }}</div>
              </div>
              <div class="flex flex-col items-end justify-start pr-4">
                <div @click="deleteDamage()" class="rounded mt-2 py-1 px-2 w-8 bg-gray-100 text-gray-600 text-center cursor-pointer hover:bg-red-300 hover:text-red-700">
                  <i class="far fa-trash-alt"></i>
                </div>
              </div>
            </div>
            <div class="px-4 pb-4">
              <div class="mt-1 relative rounded-sm shadow-sm w-full">
                <input v-model="damage.comment" type="text" class="block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 border-l-4 rounded border-gray-300 placeholder-gray-300" placeholder="Comment">
              </div>
            </div>
          </div>
          <div class="w-full pl-4 pr-2" v-if="part=='type'">
            <div class="pt-3">
              <div class="text-xl font-bold">Damage type</div>
            </div>
            <div class="pb-4 bg-white rounded-lg flex flex-wrap w-full">
              <div v-for="item in general.types" v-bind:key="item.id"  class="w-1/4 pr-2 pt-2">
                <div v-on:click="selectType(item)" class="px-2 h-10 flex flex-row items-center border-2 border-gray-200 text-gray-500 rounded hover:border-blue-500 hover:bg-blue-100 hover:text-blue-500 cursor-pointer">
                  <span class="ml-3">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full pl-4 pr-2" v-if="part=='severity'">
            <div class="pt-3">
              <div class="text-xl font-bold">Severity</div>
            </div>
            <div class="pb-4 bg-white rounded-lg flex flex-wrap w-full">
              <div v-for="item in severities" v-bind:key="item.id"  class="w-1/4 pr-2 pt-2">
                <div v-on:click="selectSeverity(item)" class="px-2 h-10 flex flex-row items-center border-2 border-gray-200 text-gray-500 rounded hover:border-blue-500 hover:bg-blue-100 hover:text-blue-500 cursor-pointer">
                  <span class="ml-3">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full pl-2 pr-4 flex flex-wrap" v-if="part=='pictures'">
            <div class="pl-2 pt-2 w-1/3"><div class="h-32 bg-blue-50 rounded text-blue-300 flex justify-center items-center hover:bg-blue-100 hover:text-blue-400 cursor-pointer">Drop here</div></div>
            <div class="pl-2 pt-2 w-1/3"><div class="h-32 bg-blue-50 rounded text-blue-300 flex justify-center items-center hover:bg-blue-100 hover:text-blue-400 cursor-pointer">Drop here</div></div>
            <div class="pl-2 pt-2 w-1/3"><div class="h-32 bg-blue-50 rounded text-blue-300 flex justify-center items-center hover:bg-blue-100 hover:text-blue-400 cursor-pointer">Drop here</div></div>
            <div class="pl-2 pt-2 w-1/3"><div class="h-32 bg-blue-50 rounded text-blue-300 flex justify-center items-center hover:bg-blue-100 hover:text-blue-400 cursor-pointer">Drop here</div></div>
            <div class="pl-2 pt-2 w-1/3"><div class="h-32 bg-blue-50 rounded text-blue-300 flex justify-center items-center hover:bg-blue-100 hover:text-blue-400 cursor-pointer">Drop here</div></div>
            <div class="pl-2 pt-2 w-1/3"><div class="h-32 bg-blue-50 rounded text-blue-300 flex justify-center items-center hover:bg-blue-100 hover:text-blue-400 cursor-pointer">Drop here</div></div>
          </div>
        </div>
      </div>
      <div class="w-96 flex flex-col border-l-2 border-gray-200 pl-2">
        <div v-for="damage in lead().condition.damages" v-bind:key="damage.id" @click="selectDamage(damage)" class="bg-blue-50 text-xs flex flex-row mb-1 pr-2 pt-1 rounded border-blue-50 border-2 hover:border-blue-500 hover:bg-blue-50 cursor-pointer">
          <div class="w-1/3 flex justify-center items-center p-2 overflow-hidden" style="max-height:48px;min-height:48px;">
            <img :src="require(`@/assets/img/damages/img/${damage.item}.png`)" class="w-full">
          </div>
          <div class="flex flex-col w-2/4 text-gray-600">
            <div class="font-medium text-blue-600 font-bold truncate">{{ damage.locationText }}&nbsp;</div>
            <div class="truncate">{{ damage.typeText }}&nbsp;</div>
            <div class="truncate">{{ damage.severityText }}&nbsp;</div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row rounded-full text-blue-500 border border-gray-300 font-bold">
              <div class="pl-2 w-full rounded-l-full text-gray-300"><i class="far fa-camera"></i></div>
              <div class="pl-1 pr-2 w-full rounded-r-full flex justify-center item-center">{{ damage.pictures.length > 0 ? damage.pictures.length : '' }}</div>
            </div>
            <div class="flex flex-row rounded-full text-blue-500 border border-gray-300 font-bold mt-2">
              <div class="pl-2 w-full rounded-r-full text-gray-300"><i class="far fa-comments"></i></div>
              <div class="pl-1 pr-2 w-full rounded-r-full flex justify-center items-center" style="min-width: 24px;"><i v-if="nil(damage.comment).trim().length > 0" class="fa fa-check"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import {modalInfo} from "@/common/modal";
import {generateUUID, nil} from "@/common";
import SVGInject from '@iconfu/svg-inject';
import modal from "@/components/modal";

export default {
  name: "Damages",
  data() {
    return {
      modal: modalInfo(),
      loading: true,
      general: null,
      partActive: '',
      part: 'general',
      parts: ['front', 'left','rear','right','top','inside-front','inside-back','parts'],
      mountedParts: [],
      progress: null,
      hoverLocationId: null,
      severities: [],
      damage: null,
      prevFill: null
    }
  },
  components: {
    'modal': modal
  },
  methods: {
    lead() {
      return this.$store.getters['seller/lead'];
    },
    nil: nil,
    selectMenu(menu) {
      this.$emit('select-detail-menu', menu);
    },
    uploadProgress(progress) {
      this.progress = progress;
    },
    signalLoaded() {
      this.loading = false;
    },
    getLocationName() {
      if(this.hoverLocationId===null) return null;
      const locs = _.filter(this.general.locations, loc => { return this.hoverLocationId == loc.id});
      return locs.length>0 ? locs[0].name : null;
    },
    selectType(damageType) {
      this.damage.typeId = damageType.id
      this.damage.typeLabel = damageType.name
      this.damage.typeText = damageType.name
      this.severities = []
      this.part = damageType.severities.length>0 ? 'severity' : 'pictures'
    },
    deleteDamage() {
      this.modal.view('Damage', 'Are you sure to delete this damage', 'Delete', 'exclamation-triangle', () => {
        this.part = 'general';
        _.remove(this.lead().condition.damages, (damage) => { return damage.id === this.damage.id });
        this.damage == null;
      })
    },
    selectDamage(damage) {
      this.damage = damage;
      this.part = "pictures";
      if(this.damage.typeId===null || this.damage.typeId===0) this.part = "type";
    },
    bindActivity() {
      window.$("path").unbind();
      window.$("path").hover(function(e) {
        if(e.type === "mouseenter") {
          if(nil(e.target.id).startsWith('location')||nil(e.target.id).startsWith('part')) {
            this.prevFill = e.target.style.fill;
            e.target.style.fill = 'red';
            e.target.style.fillOpacity = 0.4;
            this.hoverLocationId = e.target.id.split('-')[1];
          }
        } else if(e.type === "mouseleave") {
          if(this.prevFill!==null) {
            e.target.style.fill = this.prevFill;
            e.target.style.fillOpacity = 0;
          }
          this.prevFill = null;
          this.hoverLocationId = null;
        }
      }.bind(this));
      window.$("path").click(function(e) {
        if(nil(e.target.id).startsWith('location')||nil(e.target.id).startsWith('part')) {
          e.target.style.fill = 'red';
          e.target.style.fillOpacity = 0.4;
          this.hoverLocationId = e.target.id.split('-')[1];
          const damage = {
            comment: "",
            damageId: 0,
            id: generateUUID(),
            item: this.partActive,
            locationId: this.hoverLocationId,
            locationLabel: this.getLocationName(),
            locationText: this.getLocationName(),
            note: "",
            partIndex: null,
            pictures: [],
            severityId: null,
            severityLabel: '',
            severityText: '',
            typeId: null,
            typeLabel: '',
            typeText: '',
            w: null,
            x: null,
            y: null
          }
          this.damage = damage;
          this.lead().condition.damages.push(damage);
          this.part = 'type';
        }
      }.bind(this));
    },
    selectMain(part) {
      this.part = "general";
      this.partActive = part;
      this.damage = null;
      if(!this.mountedParts.includes(part))  {
          SVGInject(document.getElementById("svg-part-" + part)).then(() => {
          console.log('bind it');
          this.bindActivity();
        });
        this.mountedParts.push(part);
      } else {
        console.log('bind it');
        this.bindActivity();
      }


    }
  },
  watch: {
  },
  created() {
    if(this.$store.getters['tradein/damageData']!==null) {
      this.general = this.$store.getters['tradein/damageData'];
      this.signalLoaded();
    } else {
      axios.get('/manager/fleet/damage/all/').then(response => {
        if (response.status === 200) this.$store.commit('tradein/setDamageData', response.data);
        this.general = response.data;
        this.signalLoaded();
      });
    }
  },
  mounted: function() {
    console.log('mounted damages');
    // _.each(this.parts, (part) => {
    //     SVGInject(document.getElementById("svg-part-" + part));
    // });
  },
}
</script>

<style scoped>

</style>